
import { Component, Vue, Prop, Watch, Ref } from 'vue-property-decorator'
import { ElForm } from 'element-ui/types/form'
import { ElTable } from 'element-ui/types/table'

@Component({
  name: 'DailyTemplateListAdd'
})
export default class extends Vue {
  @Ref('table') readonly table!: ElTable
  @Prop() readonly addShow!: boolean
  @Prop() readonly id!: string
  // @Prop() readonly projectIds!: any

  private info = {
    templateId: '',
    isDisable: '',
    templateName: '',
    projectIds: [],
    optionList: [] as any
  }

  private title = '新增日报模板'

  private BindingProjectList = [] as any // 绑定项目模板
  private submitShow = false
  private dailyOptionList = [] as any // 日报配置
  private rules= {
    templateName: [
      { required: true, message: '请输入模板名称', trigger: 'change' }
    ],
    projectIds: [
      { required: true, message: '请选择使用项目', trigger: 'change' }
    ]
    // ,
    // optionList: [
    //   { required: false, message: '请选择日报项', trigger: 'change' }
    // ]
  }

  get projectList () {
    let projectList = []
    projectList = this.$store.state.projectList.filter((item: any) => {
      return this.BindingProjectList.every((k: any) => k !== item.projectId)
    })
    // console.log('projectList', projectList)
    // this.BindingProjectList
    // if (this.id && this.id !== 'add') {
    //   console.log('添加自身id')
    //   projectList = [...projectList, ...this.projectIds]
    // }
    return projectList
  }
  // @Watch('detailShow', { immediate: true })
  // isDetail (val: string) {
  //   val && this.getDetail()
  // }

  @Watch('id')
  isTrue (val: string) {
    this.$nextTick(() => {
      this.table.clearSelection()
    })
    if (val === 'add') {
      // console.log('新增')
      this.title = '新增日报模板'
      this.getBindingProjectTemptate() // 获取已经绑定的项目模板（绑定过的不能选）
      this.getDailyReportList()// 获取日报项列表
    } else if (val) {
      // console.log('编辑')
      this.title = '编辑日报模板'
      const res1 = this.$axios.get(this.$apis.maintenanceDaily.selectYhProjectDailyTemplateList) // 获取已经绑定的项目模板（绑定过的不能选）
      const res2 = this.$axios.get(this.$apis.common.selectDicByList, { dicType: 'dailyOptionType' }) // 获取日报项列表
      const res3 = this.$axios.get(this.$apis.maintenanceDaily.selectYhDailyTemplateByTemplateId, { templateId: this.id }) // 模板详情
      Promise.all([res1, res2, res3]).then((res) => {
        // console.log('Promiseres', res)
        // 回显日报模板名称
        this.info.templateName = res[2].templateName
        this.info.templateId = res[2].templateId
        this.info.isDisable = res[2].isDisable

        // 处理项目
        this.BindingProjectList = res[0].filter((item: any) => {
          return item.templateId
        }).map((k: any) => {
          return k.projectId
        })

        const arr = [] as any
        res[2].projectList.forEach((item: any) => {
          arr.push({
            projectId: item.projectId,
            projectName: item.projectName
          })
        })
        this.projectList.push(...arr)
        this.info.projectIds = res[2].projectList.map((item: any) => item.projectId)

        // 处理日报项
        res[1].dailyOptionType.forEach((item: any) => {
          item.isShare = 0 // 默认为不分享状态
          item.flag = false // 默认禁用分享状态
        })
        this.dailyOptionList = res[1].dailyOptionType
        this.dailyOptionList.forEach((item: any) => {
          let isDisplay = false
          isDisplay = res[2].optionList.some((k: any) => {
            return item.dicCode === k.dailyOptionType
          })
          if (isDisplay) {
            this.$nextTick(() => {
              this.table.toggleRowSelection(item, true)
              const target = res[2].optionList.find((t: any) => {
                return item.dicCode === t.dailyOptionType
              })
              // 回显禁用和分享状态
              if (item.dicCode !== '1') {
                item.isShare = target.isShare
                item.flag = true
              }
              // console.log('target', target)
            })
          }
        })
      })
    }
  }

  created () {
    // this.getBindingProjectTemptate() // 获取已经绑定的项目模板
  }

  getBindingProjectTemptate () {
    this.$axios.get(this.$apis.maintenanceDaily.selectYhProjectDailyTemplateList).then(res => {
      this.BindingProjectList = res.filter((item: any) => {
        return item.templateId
      }).map((k: any) => {
        return k.projectId
      })
      // this.BindingProjectList = ['20211220093111461403']
      // console.log('this.BindingProjectList', this.BindingProjectList)
    })
  }

  getDailyReportList () {
    this.$axios.get(this.$apis.common.selectDicByList, { dicType: 'dailyOptionType' }).then(res => {
      // console.log('res3', res)
      res.dailyOptionType.forEach((item: any) => {
        item.isShare = 0
        item.flag = false
      })
      this.dailyOptionList = res.dailyOptionType
    })
  }

  getDetail () {
    this.$axios.get(this.$apis.maintenanceDaily.selectYhDailyTemplateByTemplateId, { templateId: this.id }).then(res => {
      if (res) {
        this.info = res
      }
    })
  }

  // 选择日报项-处理禁用和关联分享的选中状态
  select (val: any, row: any) {
    // console.log('select', val, row)
    // this.$nextTick(() => {
    if (row.dicCode !== '1') {
      if (row.flag) {
        row.isShare = 0
      } else {
        row.isShare = 1
      }
      row.flag = !row.flag
    }
  }

  selectAll (val: any) {
    // console.log('selectAll', val)
    if (val.length) {
      this.dailyOptionList.forEach((item: any) => {
        this.$nextTick(() => {
          if (item.dicCode !== '1') {
            item.isShare = 1
            item.flag = true
          }
        })
      })
    } else {
      this.dailyOptionList.forEach((item: any) => {
        this.$nextTick(() => {
          if (item.dicCode !== '1') {
            item.isShare = 0
            item.flag = false
          }
        })
      })
    }
  }

  // 选择日报项-处理提交的值
  selectionLineChangeHandle (val: any) {
    // console.log('selectionLineChangeHandle', val)
    this.info.optionList = val
  }

  saveForm () {
    // console.log('this.info', this.info);
    // console.log('this.dailyOptionList', this.dailyOptionList);
    (this.$refs.info as ElForm).validate(valid => {
      if (valid) {
        if (!this.info.optionList.length) {
          this.$message.warning('至少添加一项日报项！')
          return
        }
        const params = JSON.parse(JSON.stringify(this.info))
        params.optionList = params.optionList.map((item: any) => {
          return {
            dailyOptionType: item.dicCode,
            isShare: parseInt(item.isShare)
          }
        })
        this.submitShow = true
        const urls = this.id === 'add' ? this.$apis.maintenanceDaily.insertYhDailyTemplate : this.$apis.maintenanceDaily.updateYhDailyTemplate
        this.$axios.post(urls, params).then(() => {
          this.$message.success('保存成功')
          this.handleClose()
          this.$emit('success')
        }).finally(() => {
          this.submitShow = false
        })
      }
    })
  }

  handleClose () {
    // console.log('关闭弹窗')
    this.$emit('update:addShow', false)
    this.$emit('update:id', '')
    this.info = {
      templateId: '',
      isDisable: '',
      templateName: '',
      projectIds: [],
      optionList: [] as any
    }
    this.BindingProjectList = []
    this.dailyOptionList = [];
    (this.$refs.info as any).resetFields()
  }
}
