
import { Component, Vue } from 'vue-property-decorator'
import Add from './DailyTemplateListAdd.vue'

@Component({
  name: 'maintenanceDay',
  components: { Add }
})

export default class extends Vue {
  private info = {
    templateName: '',
    projectIds: [],
    optionList: []
  }

  private addShow = false
  private id = '' // 编辑的时候可以使用

  private page = 1
  private size = 10
  private total = 0
  private loading = false
  private tableData = []

  get projectList () {
    return this.$store.state.projectList
  }

  created () {
    this.loadData()
  }

  onSearch () {
    this.page = 1
    this.loadData()
  }

  loadData () {
    this.loading = true
    this.$axios
      .get(
        this.$apis.maintenanceDaily.selectYhDailyTemplateByPage,
        {
          ...this.info,
          page: this.page,
          size: this.size
        }
      )
      .then((res) => {
        if (res) {
          this.total = res.total || 0
          this.tableData = res.list || []
        }
      })
      .finally(() => {
        this.loading = false
      })
  }

  // 新增
  onAdd () {
    // console.log('新增')
    // this.$router.push({ name: 'addTeams' })

    this.id = 'add'
    this.addShow = true
  }

  // 编辑
  onEdit (id: string) {
    this.id = id
    this.addShow = true
  }

  // 修改状态
  onExamine (templateId: any, status: any) {
    const tips = status === 0 ? '禁用' : '启用'
    this.$confirm(`确认${tips}吗, 是否继续?`, '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      this.$axios.post(this.$apis.maintenanceDaily.disableYhDailyTemplate, { templateId }).then(() => {
        this.$message.success('操作成功')
        this.loadData()
      })
    })
  }

  // 删除
  onDelete (templateId: string) {
    this.$confirm('确认删除吗, 是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    })
      .then(() => {
        this.loading = true
        this.$axios.post(this.$apis.maintenanceDaily.deleteYhDailyTemplate, { templateId }).then(() => {
          this.$message.success('删除成功')
          this.onSearch()
        }).finally(() => {
          this.loading = false
        })
      })
      .catch((err) => {
        console.log(err)
      })
  }
}
